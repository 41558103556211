<template>
  <v-btn
    fab
    :loading="loading"
    :color="
      $store.getters.isThemeDark
        ? 'white accent-4 black--text'
        : 'blue darken-4 white--text'
    "
    @click="googleSignIn()"
  >
    <v-img max-width="2rem" :contain="true" src="/img/google.logo.svg"></v-img>
  </v-btn>
</template>

<script>
import { Device, DeviceInfo } from "@capacitor/device";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import localforage from "@/plugins/localforage";
export default {
  name: "login-google-btn",
  props: ["title"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async googleSignIn() {
      this.loading = true;
      const deviceInfo = await Device.getInfo();
      if (deviceInfo.platform === "web") {
        await GoogleAuth.init();
      }
      return setTimeout(() => {
        return GoogleAuth.signIn()
          .then((gUser) => {
            this.loading = false;
            return this.sendToServer(gUser);
          })
          .catch((err) => {
            this.loading = false;
            console.error("Error", err);
          });
      }, 1000);
    },

    sendToServer(googleUser) {
      this.loading = true;
      if (googleUser.authentication && googleUser.authentication.accessToken) {
        const access_token = googleUser.authentication.accessToken;
        return this.$store
          .dispatch("auth/loginSignupWithGoogle", {
            access_token,
          })
          .then((res) => {
            this.loading = false;
            this.$emit("afterSuccess", res);
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (googleUser.serverAuthCode) {
        const code = googleUser.serverAuthCode;
        return this.$store
          .dispatch("auth/loginSignupWithGoogleAuthCode", {
            code,
          })
          .then((res) => {
            this.loading = false;
            this.$emit("afterSuccess", res);
          })
          .catch(() => {
            this.loading = false;
          });
      }
      this.loading = false;
      // everything failed, notify user
    },
  },
};
</script>

<style>
</style>