<template>
  <v-row dense no-gutters class="d-flex justify-center align-center">
    <v-col cols="12" md="5">
      <v-img src="/artwork/login.png"></v-img>
    </v-col>
    <v-col cols="12" md="5" class="d-flex justify-center flex-column">
      <h1 class="display-1 mt-3 mb-0 text-center">
        {{ showEmailTab ? $lang("LOGINWITHEMAIL") : $lang("LOGINWITHMOBILE") }}
      </h1>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="deep-orange accent-4"
      ></v-progress-linear>

      <login-with-email v-if="showEmailTab" />
      <login-with-mobile
        v-if="!showEmailTab && auth_config && auth_config.mobile_signin"
      />

      <v-card color="transparent" elevation="0">
        <v-card-text class="text-center">
          {{ $lang("ORLOGINWITH") }}
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-center align-center">
          <login-google-btn
            v-if="auth_config && auth_config.google_signin"
            title="SignInGoogle"
            @afterSuccess="afterSuccess(true)"
          />

          <v-btn
            @click="showEmailTab = !showEmailTab"
            fab
            :color="
              $store.getters.isThemeDark
                ? 'white accent-4 black--text'
                : 'blue darken-4 white--text'
            "
          >
            <v-icon v-if="showEmailTab">mdi-phone</v-icon>
            <v-icon v-else>mdi-email</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card-text class="text-center">
        {{ $lang("DONTHAVEANACCOUNT") }}&nbsp;
        <a
          style="text-decoration: none"
          @click="$router.push({ name: 'register' })"
        >
          {{ $lang("Signup") }}
        </a>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import bus from "@/bus";
import localforage from "@/plugins/localforage";
import { isEmpty } from "lodash";
import { axios } from "@/plugins/axios";
import LoginWithMobile from "../modules/LoginWithMobile";
import LoginWithEmail from "../modules/LoginWithEmail";
import LoginGoogleBtn from "../modules/LoginGoogleBtn";
export default {
  name: "login",
  components: {
    LoginWithMobile,
    LoginWithEmail,
    LoginGoogleBtn,
  },
  data() {
    return {
      loading: false,
      auth_config: {
        google_signup: false,
        mobile_signup: false,
        email_signup: false,
      },
      showEmailTab: false,
    };
  },
  computed: {},
  methods: {
    fetchAuthConfig() {
      this.loading = true;
      bus.$emit("showWait");
      return axios
        .get("globals/auth_config")
        .then((res) => {
          this.auth_config = res.data.value;
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    afterSuccess(verified) {
      console.log("success");
      return localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }

          if (verified) {
            //remove this key if user is varified
            localforage.removeItem("intended");
          }

          this.$router.replace(intended);
        })
        .catch((err) => {
          console.error(err);
          this.$router.replace({ name: "home" });
        });
    },
  },
  mounted() {},
  created() {
    this.fetchAuthConfig();
  },
  destroyed() {},
};
</script>


